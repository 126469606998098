/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/382b6f43e60580be-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/42aa6e6ea3b85651-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7bafef50a4560c1d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bc270b3a577192a7-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b32bc20a1e277f31-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/357398c3a461852b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4d1af68a2715a16a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/016998512d65d2ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/670e7515c0ec7969-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/18699a9281f7a2a2-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e95b56c55e926f76-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/05aedd27f74b307f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5c321730472a6124-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5ea63b290dc24b98-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d1efd2a880aff16c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5617f5a793538eec-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a730c2098cf2d406-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10020a8c5cd83a9c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26e111bc0bbb9793-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1d0c179378730186-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7806ef5df85cda4a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6821bb67d8dbee23-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fe049333cc50d32b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a8f1d802d3a50c22-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d5e97bb9bb0471a9-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bf219fb9b8e9c4d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2955b29b2e789dbf-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_347144';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/83c07cea5b6249d7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Sans_Fallback_347144';src: local("Arial");ascent-override: 91.01%;descent-override: 25.79%;line-gap-override: 0.00%;size-adjust: 102.74%
}.__className_347144 {font-family: '__Fira_Sans_347144', '__Fira_Sans_Fallback_347144';font-style: normal
}.__variable_347144 {--font-sans: '__Fira_Sans_347144', '__Fira_Sans_Fallback_347144'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9f05b6a2725a7318-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c740c1d45290834f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d0697bdd3fb49a78-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9bbb7f84f3601865-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a8eac78432f0a60b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/122c360d7fe6d395-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9f05b6a2725a7318-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c740c1d45290834f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d0697bdd3fb49a78-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9bbb7f84f3601865-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a8eac78432f0a60b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/122c360d7fe6d395-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9f05b6a2725a7318-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c740c1d45290834f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d0697bdd3fb49a78-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9bbb7f84f3601865-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a8eac78432f0a60b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Code_2f1cd4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/122c360d7fe6d395-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Code_Fallback_2f1cd4';src: local("Arial");ascent-override: 73.56%;descent-override: 23.92%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_2f1cd4 {font-family: '__Fira_Code_2f1cd4', '__Fira_Code_Fallback_2f1cd4';font-style: normal
}.__variable_2f1cd4 {--font-mono: '__Fira_Code_2f1cd4', '__Fira_Code_Fallback_2f1cd4'
}

